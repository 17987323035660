import { Link, useNavigate } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import defaultImage from "../../assets/images/defaultUser.png";
import React, { useState } from "react";
import { setPublicReceived } from "../../store/users";
import { useDispatch, useSelector } from "react-redux";
import { getPublicDreamDonations } from "../../services/api";
import DreamMakersModal from "../DreamMakersModal";
import "../../styles/BirthdaySlider.css";
import coverImg from "../../assets/images/coverImg.png";
import { UsersModule } from "../../types/redux";
import { calculateDreamProgress } from "../../utils/calculateDreamProgress";
import { SliderCardUserProps } from "../../types/models";
import { generateTooltipKey } from "src/utils/gerenateTooltipKey";
import TooltipDetails from "../Tooltip/TooltipDetails";
import { fulfillCheck } from "src/utils/fulfillCheck";

const BirthdayCard = ({ dream, isFulfilled }: SliderCardUserProps) => {
  const progress = calculateDreamProgress(dream.dreams[0]);
  const navigate = useNavigate();
  const tooltipKey = generateTooltipKey(dream?.id);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const publicReceived = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicReceived;
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;
  const isDreamFulfilled = fulfillCheck(
    allFulfilled,
    dream.dreams[0],
    dream?.id
  );
  const progressClick = () => {
    dispatch(setPublicReceived({}));
    setShow((prev) => !prev);
    getPublicDreamDonations(dream?.dreams[0].id, 1).then((res) => {
      dispatch(setPublicReceived(res?.data));
    });
  };

  return (
    <div className="birthday-card shadow-card rounded-3">
      <div className="birthday-card-header">
        <img
          src={dream?.coverImage?.url || coverImg}
          alt="profile-img"
          className="birthday-card-header"
        />
      </div>
      <Link
        to={`/dashboard/public-page/${dream?.id}`}
        style={{ height: "0px" }}
      >
        <div className="bg-white border-radius-img birthday-img-mg">
          <img
            src={dream?.mainImageUrl || defaultImage}
            alt="profile-img"
            className="bg-white birthday-avatar"
          />
        </div>
      </Link>
      <div className="birthday-main-box">
        <div className="birthday-user-container">
          <Link to={`/dashboard/public-page/${dream?.id}`}>
            <div className="birthday-user-box" data-tooltip-id={tooltipKey}>
              <span className="birthday-card-name my-name-span lh-sm text-underline">
                {`${dream?.firstName || "No Name"} ${dream.lastName || ""}`}
              </span>
              <span className="birthday-emoji">🎉</span>
            </div>
          </Link>
          <TooltipDetails
            userId={dream?.id}
            isFulfilled={isFulfilled}
            allFulfilled={allFulfilled}
            tooltipKey={tooltipKey}
          />
        </div>
        <div
          className="text-dark cursor-pointer birthday-dream-box"
          style={{ width: "-webkit-fill-available" }}
        >
          <span className="my-span lh-sm dream-description">
            {(dream && dream.dreams.length > 0 && dream?.dreams[0]?.title) ||
              "No Title"}
          </span>
        </div>
        <div className="progress-box">
          <div className="scope-container d-flex align-items-center justify-content-between">
            <p className="dream-scope">Scope</p>
            <p className="birthday-dream-percentage">
              {isNaN(progress) ? 0 : progress}%
            </p>
          </div>
          <div className="dream-progress-container">
            <ProgressBar
              onClick={() => progressClick()}
              style={{ width: "100%", height: "14px", cursor: "pointer" }}
              now={progress}
              variant={"gradient"}
              className={"black-hover"}
            />
          </div>
        </div>
        {publicReceived?.results?.length ? (
          <DreamMakersModal
            donations={publicReceived}
            show={show}
            setShow={setShow}
            dreamId={dream.dreams[0].id}
            card={true}
          />
        ) : (
          <></>
        )}
        <div className="d-flex justify-content-between sliders-button">
          <div
            className="custom-gradient sliders-button"
            onClick={() =>
              isDreamFulfilled
                ? navigate(`/dashboard/public-page/${dream?.id}`)
                : isFulfilled(dream?.dreams[0].id)
            }
          >
            <div className="dashboard-card-buttons-text">
              <span>
                {isDreamFulfilled
                  ? "View Profile"
                  : `Make ${dream?.gender === "FEMALE" ? "Her" : "Him"} a gift`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BirthdayCard;
